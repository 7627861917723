const counterInitialState={
    counter:0
}


export const ItemCounter =(state=counterInitialState,action)=>{
    switch(action.type){
        case 'INCREMENT_COUNT': 
        console.log('action.payload.data',action.payload)

         if(action.payload.data.id === action.payload.id)
         {
           return  {...state, counter: ++action.payload.data.count};
         }
         
        case 'DECREMENT_COUNT':
            if(action.payload.data.count>0){
                if(action.payload.data.id === action.payload.id)
                {
                  return  {...state, counter: --action.payload.data.count};
                }

         }
        default:
            return state;    
    }

}


const itemslist=[]
export const ItemsListReducer =(state=itemslist, action)=>{
    switch(action.type){
          
         case "ITEMS_LIST_STORING": 
                 console.log('state',state,itemslist)
           return  {...state, itemslist: action.payload};
 
        default:
            return state;    
    }

}
const otpnumber=''
export const otpNumberReducer =(state=itemslist, action)=>{
    switch(action.type){
         case "OTP_NUMBER": 
           return  {...state, otpnumber: action.payload};
 
        default:
            return state;    
    }

}