import axios from "axios"

export const login=(values)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}login/v1/login`,{
       emailId:values?.email,
       password:values?.password
     }).then((res)=>{
        return res
      })
   }
export const sendMessage=(phoneNo,message)=>{
      return axios.post(`${process.env.REACT_APP_APIORDERAT_URL}user/v1/sendMessage`,{
       number:`+${phoneNo}`,
       message:`Your verification code is: ${message}`
    //    message:`Your One-Time Password (OTP) verification code is:${message}`
      }, {
       headers:{
           'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
           'Content-Type': 'application/json', 
       }
   })   .then((response)=>{
               console.log('Send Message response', response)
           return response
           })
           .catch((error)=>{
           console.log('Send Message Error', error)
           return error
           })
   
      }
      export const sendMessagetwo=(phoneNo,message)=>{
         return axios.post(`${process.env.REACT_APP_APIORDERAT_URL}user/v1/sendMessage`,{
          number:`+${phoneNo}`,
          message:message
         }, {
          headers:{
              'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
              'Content-Type': 'application/json', 
          }
      })   .then((response)=>{
                  console.log('Send Message response', response)
              return response
              })
              .catch((error)=>{
              console.log('Send Message Error', error)
              return error
              })
         }
         export const sendmsglocation=(phoneNo,message)=>{
            return axios.post(`${process.env.REACT_APP_APIORDERAT_URL}user/v1/sendMessage`,{
             number:`+${phoneNo}`,
             message:`Please click the link and scan the QR Code - ${message}`
            }, {
             headers:{
                 'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
                 'Content-Type': 'application/json', 
              } }) .then((response)=>{
                    //  console.log('Send Message Location response', response)
                      return response
                 })
                 .catch((error)=>{
                    //  console.log('Send Message Location Error', error)
                    return error
                 }) 
     }             
      export const placeorder=  (deliveryLocation, contactno , orderId,itemName,itemQuantity)=>{
        // let newarray =itemName.concat(itemQuantity)
        let stringnew=""
        let finalData=[]
        for (var i=0;i<itemName.length;i++){
            stringnew=stringnew+itemQuantity[i]+'x'+itemName[i]+' '  
        }
        // let finalstring = JSON.stringify(finalData)
        console.log('stringnew',stringnew)
         return axios.post(`https://api.orderat.io/order/v1/placeOrder`,{
             orderId: orderId,
             fleetId:"639071fc9b38502eefe86932",
             customerName:'',
             customerMobileNumber:contactno,
             customerEmail:stringnew,
             orderDetails:'',
             orderValue:'999',
             pickupId: "KITCHEN",
             dropId: deliveryLocation,
             qrcodePickup:orderId,
             qrcodeDrop: orderId,
             timeout: "180",
             timeoutBeh: "1000",
             deadline:"1000"
         }, {
                headers:{
                    'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
                    'Content-Type': 'application/json', 
                }
            })
                 .then((response)=>{
                    console.log(' Placeorder response', response)
                   return response
                 })
                 .catch((error)=>{
                  console.log('placeorder Error', error)
                  return error
                 })
        }      

        export const getlocationlistmapId=()=>{
         return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}location/v1/getLocationListByMapId`,{
            mapId:'6389923e3de3c52312ac855c'
          }, {
            headers:{
                'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
                'Content-Type': 'application/json', 
            }
        }).then((res)=>{
             return res
           })
        } 
        export const getdeliverytime=(orderId)=>{
            return axios.post(`https://api.orderat.io/order/details`,{
                orderId:orderId
             }, {
               headers:{
                   'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
                   'Content-Type': 'application/json', 
               }
           }).then((res)=>{
                return res
              })
           } 
 export const updateOrderStatus=(orderId)=>{
            return axios.post(`https://api.orderat.io/order/updateOrderStatus`,{
                orderId:orderId,
                status:'',
                statusCode:''
             }, {
               headers:{
                   'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
                   'Content-Type': 'application/json', 
               }
           }).then((res)=>{
                return res
              })
} 

export const OrderQrcode=(orderId,url)=>{
    return axios.post(`https://api.orderat.io/order/updateQrCode `,{
        orderId:orderId,
        fleetId:'639071fc9b38502eefe86932',
        qrLink:url
     }, {
       headers:{
           'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
           'Content-Type': 'application/json', 
       }
   }).then((res)=>{
        return res
      })
}  
export const getstorelist=()=>{
    return axios.get(`${process.env.REACT_APP_NEW_ECOM_URL}/store/list`,{
        zoneId: "63b69c17a9f5f5f8311e8ea4",
        userId:"63b67fccf8be043e310ec335"
    },{
        headers:{
            'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
            'Content-Type': 'application/json', 
        }
    })
    .then((res)=>{
       return res
    })
}

export const getproductlist=(storeId)=>{
    return axios.get(`${process.env.REACT_APP_NEW_ECOM_URL}/store/list`,{
       storeId:storeId
    },{
        headers:{
            'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
            'Content-Type': 'application/json', 
        }
    })
    .then((res)=>{
       return res
    })
}


export const getOrderDetails=(orderID)=>{
    return axios.post(`${process.env.REACT_APP_SANDBOX_URL}/order/details`,{
        orderId:orderID
     },{
         headers:{
             'Authorization':`Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2JiZmQ0YWY4N2JkNzkxNzE1NWEyNjIiLCJpYXQiOjE2NzUzMTQzNDF9.s7iQ8xFUx7TDa2JtPs-UMTOBvuWl_riG0zD8LQX7lOs`,
             'Content-Type': 'application/json', 
         }
     })
     .then((res)=>{
        return res
     })
}