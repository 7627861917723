import { createStore,combineReducers } from 'redux'
import {ItemCounter,ItemsListReducer,otpNumberReducer} from './reducers/index.js'

const mutipleReducers = combineReducers({
    ItemCounter,
    ItemsListReducer,
    otpNumberReducer
})

const store = createStore(mutipleReducers);

export default store